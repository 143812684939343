import React, { useState, useEffect } from "react";
import "./usecases.css";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../components/home/platforms.module.css";
import AccordionScroll from "../components/accordion-scroll";

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";

import AssetAllocation from "../images/AssetAllocation.png";
import ComprehensiveReporting from "../images/ComprehensiveReporting.png";
import LeavesDesignLeft from "../images/Left_leaves.png";
import ManagingMultAccountPortfolios from "../images/ManagingMultAccountPortfolios.png";
import dart from "../images/Dart.png";
import people from "../images/people.png";
import notificationIcon from "../images/notificationIcon.png";
import VisionImages from "../images/VisionImages.png";
import Right_leave from "../images/Right_leave.png";
import SignUpImage from "../images/SignUpImage.png";
import { cn, generateTrackingPath, getAppBase, saveReferralCode, saveTrackingCode } from "../lib/helpers";
import Image_Placeholder from "../images/Image_Placeholder.png";
import FreeForQuestrade from "./free-for-questrade";

const PortfolioTracking = props => {
  const [active, setActive] = React.useState(0);

  const [registerPath, setRegisterPath] = useState("/register/");
  const appBase = getAppBase();
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  saveTrackingCode();
  saveReferralCode();

  useEffect(() => {
    setRegisterPath(generateTrackingPath("/register/"));
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  const { data, questrade, errors } = props;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      {
        <SEO
          title="Portfolio Tracking"
          description="Simplify your investment tracking. Get an easy-to-read view of your portfolio's performance in a single dashboard view."
        />
      }

      <section className="Container_Class">
        {/* Top heading section */}
        <section className="First_heading">
          <div className="top_Header">
            <div className="header_Wrap">
              <h1>
                Totally Clear <span className="bold_text">Portfolio Tracking</span>
              </h1>
              <p>Track everything that matters to you in a single, easy-to-read dashboard view.</p>
              <a className="btn1" href={`https://${appBase}${registerPath}`}>
                Start Tracking{" "}
              </a>
              {questrade && <FreeForQuestrade />}
            </div>
            <div className="Image_background">
              <img src={AssetAllocation} alt="" role="Presentation" />
            </div>
          </div>
        </section>

        {/* Second slanted grey section */}
        <section className="Slanted_background_grey">
          <div className="top_Header">
            <div className="Slanted_Image_background">
              <div className="Image_slanted">
                <img src={ComprehensiveReporting} alt="" role="Presentation" />
              </div>
            </div>
            <div className="header_Wrap">
              <h4>THE PROBLEM</h4>
              <h2>I Am Overwhelmed By Data</h2>
              <p>
                I want to see how my accounts are performing, but the data is all over the place. My
                broker’s interface is so confusing - nothing is where I think it should be and
                everything is outdated. I just want this to be simple.
              </p>
            </div>
          </div>
        </section>

        {/* leaves section */}
        <section className="left_leaves_design">
          <img src={LeavesDesignLeft} alt="" role="Presentation" />
        </section>

        {/* Third section  */}
        <div className="header_Wrap_center">
          <div class="text_inside">
            <h4>THE SOLUTION</h4>
            <h2>Clear Your Vision</h2>
            <p>
              Clear and simple tracking of what matters to you. Get easy-to-read reports on your
              investment growth, fees, dividend income, and more. Always remain in the loop about
              your performance with notifications.
            </p>
          </div>
        </div>

        {/* Fourth section */}
        <div className="top_Header">
          <div className="header_Wrap_text">
            <h2>See The Full Picture</h2>
            <div className="list">
              <AccordionScroll active={active} setActive={setActive}>
                <details className="list_format" open={active === 0}>
                  <summary>Connect All Your Accounts</summary>
                  <p>
                    With all your accounts in one portfolio, you can track yours, your spouse, and
                    your kids’ accounts together.
                  </p>
                </details>
                <details className="list_format" open={active === 1}>
                  <summary>Set Your Goals</summary>
                  <p>
                    {" "}
                    If you have plans for your investments, choose them as goals and run what-if
                    scenarios to understand how to reach your goals faster.
                  </p>
                </details>
                <details className="list_format" open={active === 2}>
                  <summary>View Your Reports</summary>
                  <p>
                    Get a visual on how your portfolio is performing. Review your performance with
                    simple-to-read graphs and summaries for any chosen timeframe.
                  </p>
                </details>
                <details className="list_format" open={active === 3}>
                  <summary>Manage Your Cash Notifications</summary>
                  <p>
                    Cash Notifications keep you informed when new cash or dividends hits your
                    account. Set a Cash Threshold to only receive Cash Notifications when your cash
                    account is more than your chosen threshold.
                  </p>
                </details>
                <details className="list_format" open={active === 4}>
                  <summary>Setup Your Drift Notifications</summary>
                  <p>
                    As the market moves, your portfolio accuracy will drift from your target. Choose
                    an accuracy ‘drift threshold’ to be tracked by Passiv so when your portfolio
                    accuracy drifts below that threshold target, Passiv sends you a Drift
                    Notification to take action.
                  </p>
                </details>
              </AccordionScroll>
            </div>
          </div>
          <div className="Image_background_large">
            <img src={ManagingMultAccountPortfolios} alt="" role="presentation" />
          </div>
        </div>

        {/* Section slanted green background   */}
        <section className="Slanted_background_green">
          <div className="top_Header">
            <div class="text_wrap">
              <h2>Bring Clarity To Your Portfolio</h2>
              <p>Passiv can remove the clutter so you can see clearly</p>
              <a className="btn1" href={`https://${appBase}${registerPath}`}>
                Try These Features
              </a>
            </div>

            <div className="fragments">
              <div className="tile">
                <div className="tileContainer">
                  <div className="ImageAlign">
                    <img src={people} alt="" role="people" />
                  </div>
                  <h3>Multi-Account Portfolios</h3>
                  <p>
                    If you are managing accounts for multiple family members or have more than one
                    for yourself, group your accounts into one or more portfolio groups and manage
                    them in a single unified view.
                  </p>
                </div>
              </div>

              <div className="tile">
                <div className="tileContainer">
                  <div className="ImageAlign">
                    <img src={dart} alt="" role="dart" />
                  </div>
                  <h3>Goal Tracking</h3>
                  <p>
                    Plan to buy a house, a car, or go to school? Set those as goals in Goal
                    Tracking. Plan for how frequently and how much you need to contribute, your
                    expected return, and time frame for reaching your goal.
                  </p>
                </div>
              </div>

              <div className="tile">
                <div className="tileContainer">
                  <div className="ImageAlign">
                    <img src={notificationIcon} alt="" role="Notification" />
                  </div>
                  <h3>Notifications</h3>
                  <p>
                    Stay up to date when new cash or assets hit your account, stay in the loop about
                    your portfolio performance, and keep informed about effects the market has on
                    your portfolio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section vision */}
        <section className="Vision_header">
          <div className="top_Header">
            <div className="Image_background_wrap">
              <img src={VisionImages} alt="" role="" />
            </div>
            <div className="header_Wrap">
              <h2>Clear Your Vision</h2>
              <p>Are you ready to start tracking your portfolio performance?</p>
              <a className="btn1" href={`https://${appBase}${registerPath}`}>
                Yes, I Want To Track My Portfolio
              </a>
            </div>
          </div>
        </section>

        {/* right leaves section */}
        <section className="right_leaves_design">
          <img src={Right_leave} alt="" role="" />
        </section>

        <section>
          <div className="top_Header_related_articles">
            {postNodes && (
              <BlogPostPreviewList title="Latest Posts" nodes={postNodes} browseMoreHref="/blog/" />
            )}
          </div>
        </section>

        <section className="Footer_section">
          <div className="top_Header">
            <div className="left_Footer">
              <div className="heading_wrap">
                <h2>Automate Your Learning</h2>
                <p>
                  Invest in yourself with The Index, our monthly newsletter to build your investing
                  confidence.
                </p>
                <div className="form">
                  <input type="text" placeholder="Your email"></input>
                  <a className="btn1" href={`https://${appBase}${registerPath}`}>
                    SUBSCRIBE
                  </a>
                </div>
                <p>Trusted by our 30,000+ subscribers.</p>
              </div>
            </div>
            <div className="Image_background">
              <div className="Image_Wrap"></div>
              <img src={SignUpImage} alt="" role="" />
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default PortfolioTracking;
